<template>
  <b-overlay
  :show="loading"
  rounded="lg">
    <div>
      <b-breadcrumb>
        <b-breadcrumb-item>
          <b-icon
            icon="house-fill"
            scale="1.25"
            shift-v="1.25"
            aria-hidden="true"></b-icon>
        </b-breadcrumb-item>
        <b-breadcrumb-item active>Machine</b-breadcrumb-item>
      </b-breadcrumb><br />
      <b-card title="Machine">
        <b-col>
          <b-row class="show-on-mobile">
            <b-col
              cols="6"
              style="margin-bottom:15px;">
              <b-form-group
                label="Sort By"
                label-for="sort-by-select"
                label-cols-md="0"
                label-align-sm="left"
                label-size="md"
                class="mb-0">
                <b-input-group size="sm">
                  <b-form-select
                    id="sort-by-select"
                    v-model="sortBy"
                    :options="sortOptions"
                    class="w-75">
                    <template #first>
                      <option value="">-- none --</option>
                    </template>
                  </b-form-select>
                  <b-form-select
                    v-model="sortDesc"
                    :disabled="!sortBy"
                    :options="directionOptions"
                    size="sm"
                    class="w-25">
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12">
          <b-row style="margin-bottom:15px;">
            <b-col cols="4" />
            <b-col cols="4" />
            <b-col cols="4">
              <div>
                <b-row>
                  <span class="spin" v-if="showSpinner"></span>
                </b-row>
                <!-- Search Box -->
                <b-form-input
                  v-model="search"
                  @input="debounceSearch"
                  type="search"
                  placeholder="Type to Search"
                  style="float: right;" />
              </div>
            </b-col>
          </b-row>
          <b-row
            style="margin-bottom:10px"
            class="show-on-mobile">
            <b-col>
              <b-form-checkbox
                v-model="stackedStatus"
                value="md"
                unchecked-value="false">
                Stacked Table
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <div style="overflow-x: visible;">
                <b-table
                  :filter="search"
                  hover
                  outlined
                  head-variant="dark"
                  small
                  :per-page="perPage"
                  :current-page="currentPage"
                  :items="stations"
                  :fields="table_fields"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :stacked="stackedStatus"
                  responsive="sm">
                  <template v-slot:cell(actions)="row">
                    <b-dropdown
                      variant="success"
                      size="sm"
                      text="Menu"
                      no-caret>
                      <template #button-content>
                        <feather-icon
                          size="1x"
                          icon="MenuIcon" />
                      </template>
                      <b-dropdown-item>
                        <b-button
                          variant="success"
                          size="sm"
                          :to="{
                              name: 'error-404'
                            }"
                          style="margin-right:10px">
                          <feather-icon
                            size="1x"
                            icon="EyeIcon" />
                        </b-button>
                      </b-dropdown-item>
                    </b-dropdown>
                  </template>
                </b-table>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="4"
              class="pt-1">
              <b-form-group
                label="Data Per Page"
                label-for="per-page-select"
                label-cols-md="0"
                label-align-sm="left"
                label-size="md"
                class="mb-0">
                <b-form-select
                  id="per-page-select"
                  v-model="perPage"
                  :options="pageOptions"
                  size="sm"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col class="pt-1">
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                first-number
                last-number
                class="float-right"
                aria-controls="user-table"></b-pagination>
            </b-col>
          </b-row>
        </b-col>
      </b-card>
      <!-- modal for idle time out -->
      <b-modal
        v-model="afkmodal"
        id="modal-prevent-closing"
        ref="modal"
        no-close-on-backdrop
        no-close-on-esc>
        <template #modal-header="{}">
          <!-- Emulate built in modal header close button action -->
          <h5>Pause Job</h5>
        </template>

        <form
          ref="form"
          @submit.stop.prevent="handleSubmit">
          <b-form-group
            label="Select a Reason:"
            label-for="reason-select"
            invalid-feedback="Please select a reason"
            :state="reasonState">
            <b-form-select
              id="reason-select"
              v-model="pauseReasonDefault"
              :options="pauseReasonOptions"
              :state="reasonState"
              required>
              <template #first>
                <b-form-select-option
                  :value="null"
                  disabled>-- Please select a your Pause Reason --</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </form>
        <template #modal-footer="">
          <b-button :disabled="loading" @click="restartAfkModal">save</b-button>
        </template>
      </b-modal>
    </div>
  </b-overlay>
</template>

<script>
import { userAccess } from "@/utils/utils.js";
import axios from "@/axios";

import vSelect from "vue-select";
import {
  mapActions
} from "vuex";

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      stackedStatus: "md",
      showSpinner: false,
      //sort direction list
      directions: [{
          key: false,
          label: "Asc",
          sortable: true
        },
        {
          key: true,
          label: "Desc",
          sortable: true
        },
      ],
      search: null,
      pageOptions: [5, 10, 20, 100],
      sortBy: "",
      sortDesc: false,
      perPage: 5,
      currentPage: 1,
      //for add station
      addStationModal: false,

      //Pause
      afkmodal: false,
      pauseReasonDefault: null,
      pauseReasonOptions: [],
      reasonState: null,
      pauseIntervalID: null,
      logoutIntervalID: null,
      IDLE_TIMEOUT: 0,
      idleSecondsCounter: 0,

      //for loading
      loading:false,
    };
  },
  methods: {
    ...mapActions({
      // hardwaresData: "plant/getHardwares",
      stationsData: "plant/getStations",
    }),
    
    //Pause Functions - START
    restartAfkModal() {
      this.loading = true
      if (!this.checkFormValidity()) {
        return;
      }
      document.onclick = () => {
        this.idleSecondsCounter = 0;
      };
      document.onmousemove = () => {
        this.idleSecondsCounter = 0;
      };
      document.onkeydown = () => {
        this.idleSecondsCounter = 0;
      };
      document.onscroll = () => {
        this.idleSecondsCounter = 0;
      };
      this.startPauseInterval()

      if (this.pauseReasonDefault != null) {
        var pauseData = {
          user: this.userInfo,
          pauseReason: this.pauseReasonDefault,
          pauseReference: "Cutter HMI List "
        };
        this.$store
          .dispatch("pause/savePauseJob", pauseData )
          .then((res) => {
            this.loading = false

            this.afkmodal = false;
            this.stopLogoutInterval();
            this.$bvToast.toast("PauseJob Updated", {
              title: "Success",
              variant: "success",
              solid: true,
            });
          })
          .catch((err) => {
            this.loading = false
            this.$bvToast.toast(err, {
              title: "Error",
              variant: "warning",
              solid: true,
            });
          });
      }
    },
    checkIdleTime() {
      this.idleSecondsCounter++;
      console.log(this.IDLE_TIMEOUT,":",this.idleSecondsCounter);
      if (this.idleSecondsCounter == this.IDLE_TIMEOUT) {
        var pauseData = {
          user: this.userInfo,
          pauseTimestamp: new Date(),
          props: {
            idleRun: true,
          },
          pauseInterval: this.pauseSetting.intervalTime,
        };
          this.$store
            .dispatch("pause/addPauseJob", pauseData)
            .then((res) => {
              this.stopPauseInterval();
              this.startLogoutInterval()
              this.afkmodal = true;
              this.$bvToast.toast("PauseJob Added", {
                title: "Success",
                variant: "success",
                solid: true,
              });
            })
            .catch((err) => {
              this.$bvToast.toast(err, {
                title: "Error",
                variant: "warning",
                solid: true,
              });
            });
      }
    },
    /* 
      when idle timeout modal showed, this function will started
      and running an interval to check if current time as same as
      auto logout time from pause setting, if it happend system will auto logout 
    */
    idleToLogout() {
      var TimeSet = this.pauseSetting.logoutSessionMorning.split(":");
      var TimeSet2 = this.pauseSetting.logoutSessionNight.split(":");
      console.log(new Date().getHours(), ':',TimeSet[0], ' ', new Date().getMinutes(),':' ,TimeSet[1]);
      if (
        (new Date().getHours() == TimeSet[0] &&
          new Date().getMinutes() == TimeSet[1]) ||
        (new Date().getHours() == TimeSet2[0] &&
          new Date().getMinutes() == TimeSet2[1])
      ) {
        this.stopLogoutInterval();
        var pauseData = {
          user: this.userInfo,
          pauseReason: "Idle auto Logout",
          pauseReference: "Cutter HMI List"
        };
        this.$store
        .dispatch("pause/savePauseJob", pauseData )
        .then((res) => {
          this.$bvToast.toast("PauseJob Updated", {
            title: "Success",
            variant: "success",
            solid: true,
          });
          //logout function
          axios.post("/common/auth/logout").then(() => {        
            this.$session.clear()
            this.$session.destroy()
            window.location.reload()
          })
          .catch((err) => {
            this.$bvToast.toast(err.response.data.errors[0].message, {
              title: "Failed",
              variant: "danger",
              solid: true,
            });
          });
        })
        .catch((err) => {
          this.$bvToast.toast(err, {
            title: "Error",
            variant: "warning",
            solid: true,
          });
        });
      }
    },
    startPauseInterval() {
      this.pauseIntervalID = window.setInterval(this.checkIdleTime, 1000);
      console.log("startPauseInterval init");
    },
    stopPauseInterval() {
      window.clearInterval(this.pauseIntervalID);
      console.log("stopPauseInterval init");
    },
    startLogoutInterval() {
      this.logoutIntervalID = window.setInterval(this.idleToLogout, 1000);
      console.log("startLogoutInterval init");
    },
    stopLogoutInterval() {
      window.clearInterval(this.logoutIntervalID);
      console.log("stopLogoutInterval init");
    },
    //Pause Functions - END
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.reasonState = valid;
      return valid;
    },
    debounceSearch(event) {
      this.showSpinner = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.showSpinner = false;
        this.search = event.target.value;
      }, 600);
    },
    // syncData() {
    //   this.$store.dispatch("plant/syncStations").then((res) => {
    //     this.$store.dispatch("plant/getStations")
    //   })
    // }
  },
  computed: {
    userInfo() {
      const user = this.$store.getters["auth/getActiveUser"]
      return { username: user.username, email: user.email}
    },
    pauseSetting() {
      let pauseSetting = this.$store.getters["pause/getPauseSetting"];
      return pauseSetting[0];
    },
    permission() {
      return userAccess("cutterHMIList");
    },
    hardwares() {
      return this.$store.getters["plant/getHardwares"] ?
        this.$store.getters["plant/getHardwares"].map((el) => {
          return {
            ...el,
            label: el.name,
          };
        }) : [];
    },
    stations() {
      return this.$store.getters["plant/getStations"];
    },
    rows() {
      return this.stations.length;
    },
    types() {
      return ["PLC", "RFID", "Weight", "Sensor"];
    },
    station_list_fields() {
      return ["name", "type"];
    },
    hardware_list_fields() {
      return ["name", "actions"];
    },
    hardware_list_fields_detail() {
      return ["name", "type"];
    },
    table_fields() {
      return [{
          key: "name",
          label: "Name",
          filterable: true,
          sortable: true,
          formatter: (value, key, item) => {
            return item.name;
          },
        },
        {
          key: "type",
          label: "Type",
          filterable: true,
          sortable: true,
          formatter: (value, key, item) => {
            return item.stations.length > 0 ? "PL" : "Station";
          },
          sortByFormatted: true,
          filterByFormatted: true,
          //   stickyColumn: true,
        },
        {
          key: "props.status",
          label: "Status",
          filterable: true,
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          formatter: value => {
            if (value == 'Idle') {
              return 'Idle / Free'
            }else {
              return 'In Process'
            }
          }
        },
        "actions",
      ];
    },
    // Create an options list from our fields
    sortOptions() {
      return this.table_fields
        .filter((f) => f.sortable)
        .map((f) => {
          return {
            text: f.label,
            value: f.key
          };
        });
    },
    // Create an direction list that can be saved in session
    directionOptions() {
      return this.directions
        .filter((f) => f.sortable)
        .map((f) => {
          return {
            text: f.label,
            value: f.key
          };
        });
    },
  },
  created() {
  },
  mounted() {
    // this.$store.dispatch("plant/getHardwares");
    this.$store.dispatch("plant/getStations");

    // Saving Menu Setting on localstorage session so it still same even after reloading the page
    if (this.$session.has("perPageStationManufacture")) {
      this.perPage = this.$session.get("perPageStationManufacture");
    }
    if (this.$session.has("sortByStationManufacture")) {
      this.sortBy = this.$session.get("sortByStationManufacture");
    }
    if (this.$session.has("sortDescStationManufacture")) {
      this.sortDesc = this.$session.get("sortDescStationManufacture");
    }
    // fetch data user and check if it has pausejob with idle run true
    let user = this.$store.getters["auth/getActiveUser"]
    this.$store.dispatch("pause/getActivePauseJob", user).then((res)=> {
      /* 
      result "res.pause" will be true or false
      if database has document pausejob with current username and idleRun = true,
      modal will appeared
      */
      this.afkmodal = res.pause
      res.pause ? this.startLogoutInterval() : this.startPauseInterval()
    })
    this.$store.dispatch("pause/getPauseJobSetting").then((res) =>{
      this.IDLE_TIMEOUT = res[0].intervalTime * 60
    })
    document.onclick = () => {
      this.idleSecondsCounter = 0;
    };
    document.onmousemove = () => {
      this.idleSecondsCounter = 0;
    };
    document.onkeydown = () => {
      this.idleSecondsCounter = 0;
    };
    document.onscroll = () => {
      this.idleSecondsCounter = 0;
    };

    
  },
  watch: {
    afkmodal(value) {
      if(value) {
        // get reason list of pause job
        this.$store.dispatch("plant/getReasonList").then((res) => {
          var PauseJobReason = res.filter((x) => x.reasonName == "Pause Job");
          var allreason = PauseJobReason[0].reasonList;
          var activeReason = allreason.filter((x) => x.status === "Active")
          this.pauseReasonOptions = activeReason.map((x) => x.reason)
        });
      }
    },
    // Taking the Menu Setting from localstorage session so the setting will be the same as before
    perPage(perPageNew) {
      this.$session.set("perPageStationManufacture", perPageNew);
    },
    sortBy(sortByNew) {
      this.$session.set("sortByStationManufacture", sortByNew);
    },
    sortDesc(sortDescNew) {
      this.$session.set("sortDescStationManufacture", sortDescNew);
    },
  },
  destroyed() {
    this.stopPauseInterval();
    this.stopLogoutInterval();
  },
};
</script>

<style>
@media (min-width: 761px) {
  .show-on-mobile {
    display: none !important;
  }
}

@keyframes spinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }

  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}

.spin::before {
  animation: 1.5s linear infinite spinner;
  animation-play-state: inherit;
  border: solid 5px #cfd0d1;
  border-bottom-color: #0077b3;
  border-radius: 50%;
  content: "";
  height: 20px;
  width: 20px;
  position: absolute;
  margin-top: 20px;
  transform: translate3d(-50%, -50%, 0);
  will-change: transform;
}
</style>
